/**
 * @generated SignedSource<<b268cb286e462ede18e5341e63dd5309>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyIncentiveTypeChoices = "CA" | "EX" | "PT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RecruitForm_study$data = {
  readonly defaultIncentive: string | null;
  readonly defaultIncentivePoints: number | null;
  readonly id: string;
  readonly incentiveType: ScreenersStudyIncentiveTypeChoices;
  readonly name: string | null;
  readonly namePublic: string | null;
  readonly tenant: {
    readonly enablePanelView: boolean;
    readonly externalIncentivePerParticipantCostUsdCents: number;
    readonly isSelfSignup: boolean;
    readonly requireStudyPayment: boolean;
  };
  readonly " $fragmentType": "RecruitForm_study";
};
export type RecruitForm_study$key = {
  readonly " $data"?: RecruitForm_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecruitForm_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecruitForm_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultIncentive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultIncentivePoints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentiveType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "namePublic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enablePanelView",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requireStudyPayment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "externalIncentivePerParticipantCostUsdCents",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isSelfSignup",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "acc3e9e7de283d27d09966afce7a51a5";

export default node;
