import { useIntervalEffect, useLocalStorageValue, useUpdateEffect } from "@react-hookz/web";
import { orderBy, uniqBy } from "lodash-es";
import moment, { type Moment } from "moment";
import momentTz from "moment-timezone";
import { createContext, useMemo, useState } from "react";
import { Interval } from "../constants";
import { DispatchState } from "../types";

export const isPastDate = (value: Moment) => moment(value).isBefore(moment());

export const timeWithin24Hours = (value: Moment) => moment(value).subtract(24, "hours").isBefore(moment());

export const isFutureDate = (value: Moment) => moment(value).isAfter(moment());

export const useNow = (interval: Interval = Interval.RealTime) => {
  const [now, setNow] = useState(new Date());

  useIntervalEffect(() => setNow(new Date()), interval);

  return now;
};

// Everything below and related (e.g. InputTimeZone, Moment) is duplicated in the backend app

const getTimeZones = () =>
  orderBy(
    uniqBy(
      momentTz.tz
        .countries()
        .map(x => momentTz.tz.zonesForCountry(x, true))
        .flat(),
      "name"
    ).map((x): { name: string; offset: number; abbr: string; namePretty: string; offsetPretty: string } => {
      const zone = momentTz.tz.zone(x.name);
      const offsetI = zone?.offsets.findIndex(y => y === x.offset);

      const storedValue = localStorage.getItem("hub-time-zone-preferred");
      let parsedValue: TimeZone | undefined;

      if (storedValue) {
        parsedValue = JSON.parse(storedValue) as TimeZone;
      }

      if (!zone || offsetI == null) throw new Error(`Abbreviation for timezone "${x.name}" not found`);

      const offsetPretty = `UTC${
        x.offset === 0
          ? "±00:00"
          : `${x.offset > 0 ? "-" : "+"}${moment.duration(Math.abs(x.offset), "minutes").format("hh:mm")}`
      }`;

      if (parsedValue?.name === x.name && parsedValue?.offset !== x.offset) {
        parsedValue.offset = x.offset;
        parsedValue.offsetPretty = offsetPretty;
        localStorage.setItem("hub-time-zone-preferred", JSON.stringify(parsedValue));
      }

      return {
        ...x,
        abbr: zone.abbrs[offsetI] ?? "",
        namePretty: x.name.split("/").at(-1)!.replace(/_/g, " "),
        offsetPretty: offsetPretty,
      };
    }),
    ["offset", "name"],
    ["desc", "asc"]
  );
const getTimeZone = (name: string) => getTimeZones().find(x => x.name === name);

export const useTimeZones = ({
  interval = Interval.RealTimeExpensive,
  intervalDisabled = false,
}: {
  interval?: Interval;
  intervalDisabled?: boolean;
} = {}) => {
  const [value, setValue] = useState(getTimeZones());

  useIntervalEffect(() => setValue(getTimeZones()), intervalDisabled ? undefined : interval);

  return value;
};

const getTimeZoneSystem = () => getTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);

export const useTimeZoneSystem = ({
  interval = Interval.RealTimeExpensive,
  intervalDisabled = false,
}: {
  interval?: Interval;
  intervalDisabled?: boolean;
} = {}) => {
  const [value, setValue] = useState(getTimeZoneSystem());

  useIntervalEffect(() => setValue(getTimeZoneSystem()), intervalDisabled ? undefined : interval);

  return value;
};

type IntervalDisabled = Exclude<
  Exclude<Parameters<typeof useTimeZoneSystem>[0], undefined>["intervalDisabled"],
  undefined
>;
type TimeZone = Exclude<ReturnType<typeof getTimeZone>, undefined>;
type TimeZoneContextValue = {
  getTimeZone: typeof getTimeZone;
  intervalsDisabled: IntervalDisabled;
  setIntervalsDisabled: DispatchState<IntervalDisabled> | undefined;
  setTimeZonePreferred: ((x: TimeZone | undefined) => void) | undefined;
  shiftDate: <T extends Moment = Moment>(x: T) => T;
  timeZoneOffsetDifference: number;
  timeZonePreferred: TimeZone | null | undefined;
  timeZoneSystem: TimeZone | undefined;
  timeZones: ReturnType<typeof getTimeZones>;
  unshiftDate: <T extends Moment = Moment>(x: T) => T;
};
export const TimeZoneContext = createContext<TimeZoneContextValue>({
  getTimeZone,
  intervalsDisabled: false,
  setIntervalsDisabled: undefined,
  setTimeZonePreferred: undefined,
  shiftDate: <T extends Moment = Moment>(x: T) => x,
  timeZoneOffsetDifference: 0,
  timeZonePreferred: getTimeZoneSystem(),
  timeZoneSystem: getTimeZoneSystem(),
  timeZones: getTimeZones(),
  unshiftDate: <T extends Moment = Moment>(x: T) => x,
});

export const useTimeZoneContextValue = () => {
  const [intervalsDisabled, setIntervalsDisabled] = useState(false);

  const timeZoneSystem = useTimeZoneSystem({ intervalDisabled: intervalsDisabled });
  const [timeZonePreferred, setTimeZonePreferred] = useLocalStorageValue("hub-time-zone-preferred", timeZoneSystem);
  useUpdateEffect(() => {
    if (!timeZonePreferred) setTimeZonePreferred(timeZoneSystem);
  }, [timeZonePreferred, timeZoneSystem]);

  const timeZones = useTimeZones({ intervalDisabled: intervalsDisabled });

  return useMemo(() => {
    const timeZoneOffsetDifference = (timeZonePreferred?.offset ?? 0) - (timeZoneSystem?.offset ?? 0);

    const x: TimeZoneContextValue = {
      getTimeZone,
      intervalsDisabled,
      setIntervalsDisabled,
      setTimeZonePreferred,
      shiftDate: x => moment(x).subtract(timeZoneOffsetDifference, "minute") as typeof x,
      timeZoneOffsetDifference,
      timeZonePreferred,
      timeZoneSystem,
      timeZones,
      unshiftDate: x => moment(x).add(timeZoneOffsetDifference, "minute") as typeof x,
    };

    return x;
  }, [intervalsDisabled, setTimeZonePreferred, timeZonePreferred, timeZoneSystem, timeZones]);
};
