/* eslint-disable jsx-a11y/anchor-is-valid */
import outlinePayments from "@iconify-icons/ic/outline-payments";
import outlineScreenSearchDesktop from "@iconify-icons/ic/outline-screen-search-desktop";
import { Icon } from "@iconify/react";
import { App, Button, Dropdown, Menu, Rate, Tooltip, Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import moment, { Moment as MomentType } from "moment";
import React, { ReactNode, useCallback, useContext, useState } from "react";

import type { MessageInstance } from "antd/es/message/interface";
import type { NotificationInstance } from "antd/es/notification/interface";
import type { useAppProps } from "antd/lib/app/context";
import { useFragment } from "react-relay";
import { Moment } from "..";
import {
  PROJECT_INCENTIVE_TYPES,
  PROJECT_STATUSES,
  PROJECT_TYPES,
  RESPONDENT_ICONS_MAP,
  RESPONDENT_MASTER_STATUSES,
  RESPONDENT_MASTER_STATUSES_MAP,
  SCHEDULED_BY,
  SCHEDULING_TYPE,
} from "../../constants";
import { SECONDARY_TEXT_COLOR } from "../../style";
import {
  getRespondentContext,
  getStudyContext,
  getTenantContext,
  mutation,
  TimeZoneContext,
  trackEvent,
} from "../../utils";
import { numberFormatter, usdFormatterNeat } from "../../utils/misc";
import { Cells_ApproveRejectedCell_respondent$key } from "../../__generated__/Cells_ApproveRejectedCell_respondent.graphql";
import { Cells_ApproveRejectedCell_study$key } from "../../__generated__/Cells_ApproveRejectedCell_study.graphql";
import { Cells_NextStepCell_respondent$key } from "../../__generated__/Cells_NextStepCell_respondent.graphql";
import { Cells_NextStepCell_study$key } from "../../__generated__/Cells_NextStepCell_study.graphql";
import { Cells_PaymentCell_study$key } from "../../__generated__/Cells_PaymentCell_study.graphql";
import { Cells_ReviewCell_study$key } from "../../__generated__/Cells_ReviewCell_study.graphql";
import { ConfirmExternalIncentiveModal } from "../Participants/ConfirmExternalIncentiveModal";
import ParticipantPaymentModal, { RecruitsRequiringFundingType } from "../Participants/ParticipantPaymentModal";
import { notifyApproved } from "../Participants/ParticipantsNotifications";
import { IElement, TabType } from "../Participants/ParticipantsTable";
import ResponsesCell from "./ResponsesCell";
import { ScheduleCell } from "./ScheduleCell";
import { ScheduledSlotCell } from "./ScheduledSlotCell";

const { Paragraph } = Typography;

type CellInnerProps = {
  children?: ReactNode;
  width?: string;
  height?: string;
};
export const CellInner: React.FC<CellInnerProps> = props => {
  return (
    <div
      style={{
        display: "flex",
        padding: 8,
        width: `${props?.width ? props.width : "150px"}`,
        height: `${props?.height ? props.height : ""}`,
        alignItems: "center",
        overflowWrap: "anywhere",
      }}
    >
      {props.children}
    </div>
  );
};

export const NameCell = (value: any, isTest?: boolean, onClick?: () => void) => {
  let name = "Someone";

  if (value && value.firstName && value.lastName) {
    name = value.firstName + " " + (value.panelist ? value.lastName : `${value.lastName.substr(0, 1).toUpperCase()}.`);
  } else if (value && value.firstName) {
    name = value.firstName;
  }

  return (
    <div onClick={onClick} style={{ paddingLeft: "8px" }}>
      {name}
      {isTest && (
        <>
          {" "}
          <Tooltip
            title="This is a test participant. They can complete all actions in the study except for being incentivized."
            placement="bottom"
          >
            <Icon
              icon={outlineScreenSearchDesktop}
              style={{ color: "var(--ant-warning-color)", verticalAlign: "middle", marginBottom: 4 }}
            />
          </Tooltip>
        </>
      )}
    </div>
  );
};

export const TextCell = (value: string) => {
  return <CellInner>{value}</CellInner>;
};

export const RSVPCell = ({ rsvp, schedulingType }: { rsvp: string; schedulingType: string }) => {
  let text = "";
  let icon = RESPONDENT_ICONS_MAP[RESPONDENT_MASTER_STATUSES.SCHEDULED];
  switch (rsvp) {
    case "NEEDSACTION":
      if (schedulingType === SCHEDULING_TYPE.CALENDLY) {
        text = "Calendly scheduled";
      } else {
        text = "Scheduled";
      }
      break;
    case "TENTATIVE":
      text = "Scheduled (RSVP: Maybe)";
      break;
    case "ACCEPTED":
      text = "Scheduled (RSVP: Accepted)";
      break;
    case "DECLINED":
      text = "Declined (RSVP: Declined)";
      icon = RESPONDENT_ICONS_MAP[RESPONDENT_MASTER_STATUSES.DECLINED];
      break;
    default:
      text = " - ";
  }
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Icon width="20" color={SECONDARY_TEXT_COLOR} icon={icon} />
      {text}
    </div>
  );
};

const showDenyConfirm = ({
  studyId,
  respondentId,
  modal,
  notification,
  refreshData,
}: {
  studyId: string;
  respondentId: string;
  modal: useAppProps["modal"];
  notification: NotificationInstance;
  refreshData: () => void;
}) =>
  modal.confirm({
    title: "Reject Respondent",
    content: "Do you wish to reject this respondent?",
    okText: "Yes",
    onOk: () =>
      mutation({
        variables: {
          input: {
            studyId,
            respondentId: respondentId,
          },
        },
        mutation: graphql`
          mutation Cells_DenyRespondent_Mutation($input: DenyRespondentInput!) {
            denyRespondent(input: $input) {
              respondent {
                id
                approvalStatus
                masterStatus
              }
              study {
                needsReviewCount
                rejectedCount
              }
            }
          }
        `,
      }).then(() => {
        notification.success({ message: "Participant denied" });
        refreshData();
      }),
  });

const showNoShowModal = ({
  participantId,
  message,
  modal,
  notification,
  refreshData,
}: {
  participantId: string;
  message: MessageInstance;
  modal: useAppProps["modal"];
  notification: NotificationInstance;
  refreshData: () => void;
}) => {
  modal.confirm({
    title: "Mark participant as No show",
    icon: null,
    content: (
      <div>
        <p>
          If participants didn't show up for meeting you can mark them as No show. You can't incentivize participants
          that are marked as No show.
        </p>
      </div>
    ),
    okText: "Mark as No show",
    onOk() {
      return rateParticipationMutation({ participantId, noShow: true }, refreshData).then(
        res => {
          notification.success({ message: "Participant marked as No show" });
          refreshData();
        },
        err => {
          message.error("Error marking participant No show");
          console.error(err);
        }
      );
    },
    onCancel() {},
    cancelButtonProps: {
      type: "link",
    },
  });
};

const rateParticipationMutation = (
  input: { participantId: string; noShow?: boolean; rating?: number },
  refreshData?: () => void
) =>
  mutation({
    variables: {
      input,
    },
    mutation: graphql`
      mutation Cells_RateParticipation_Mutation($input: RateParticipationInput!) {
        rateParticipation(input: $input) {
          participant {
            id
            noShow
            rating
          }
          study {
            approvedTabCount
            needsPaymentCount
            rejectedCount
            recruits {
              edges {
                node {
                  status
                }
              }
            }
          }
          respondent {
            masterStatus
          }
        }
      }
    `,
  }).then(() => {
    refreshData?.();
  });

export const ParticipationCell = ({ value }: { value: any }, refreshData?: () => void, disabled?: boolean) => {
  const desc = ["Terrible", "Bad", "Normal", "Good", "Wonderful"];

  return (
    <div style={{ paddingLeft: 6, display: "flex", alignItems: "center" }} onClick={e => e.stopPropagation()}>
      <Rate
        disabled={disabled}
        tooltips={desc}
        value={value.rating}
        onChange={v => {
          if (!disabled) {
            rateParticipationMutation({ participantId: value.id, rating: v }, refreshData);
          }
        }}
      />
    </div>
  );
};

export const FocusGroupScheduledCell = (study: any) => {
  if (!study.scheduled) return <CellInner>-</CellInner>;

  return (
    <CellInner width="220px">
      Focus Group on <Moment format="M/D/YY h:mm A">{study.scheduled as MomentType}</Moment>
      <span> - </span>
      <Moment format="h:mm A">{moment(study.scheduled).add(study.duration, "minutes") as MomentType}</Moment>
    </CellInner>
  );
};

export const PaymentCell = ({
  participant,
  study: _study,
  refreshData,
  setGivePointsToParticipantId,
}: {
  participant: any;
  study: Cells_PaymentCell_study$key;
  refreshData: () => void;
  setGivePointsToParticipantId?: (participantId: string) => void;
}) => {
  const study = useFragment(
    graphql`
      fragment Cells_PaymentCell_study on StudyNode {
        defaultIncentive
        defaultIncentivePoints
        incentiveType
        status
        tenant {
          customportal {
            hidePointsAndRedemption
          }
        }
        ...ConfirmExternalIncentiveModal_study
        ...ParticipantPaymentModal_study
      }
    `,
    _study
  );

  const { message, modal, notification } = App.useApp();
  const { shiftDate } = useContext(TimeZoneContext);

  const [showParticipantPaymentModal, setShowParticipantPaymentModal] = useState<boolean>(false);
  const [showConfirmExternalIncentiveModal, setShowConfirmExternalIncentiveModal] = useState(false);
  const [recruitsRequiringFunding, setRecruitsRequiringFunding] = useState<RecruitsRequiringFundingType | null>(null);

  const rewardMutation = () => {
    return mutation({
      variables: {
        input: {
          participantId: participant.id,
        },
      },
      mutation: graphql`
        mutation Cells_RewardParticipant_Mutation($input: RewardParticipantInput!) {
          rewardParticipant(input: $input) {
            participant {
              id
              incentivized
              incentivizedAmount
              incentivizedOn
              incentivizedType
              incentivizedPointAmount
            }
            study {
              needsPaymentCount
              completeCount
              rewardedCount
              goalPaidForCount
              totalIncentives
              totalFees
              totalPerParticipantCosts
              totalDeposits
              totalPayouts
              totalRefunds
              totalRefundsDue
              recruits {
                edges {
                  node {
                    name
                    rewardedCount
                    goalPaidForCount
                    incentive
                    fee
                    totalDeposits
                    totalPayouts
                    totalRefunds
                    totalRefundsDue
                    status
                  }
                }
              }
            }
            respondent {
              masterStatus
            }
          }
        }
      `,
      silenceDefaultError: true,
    });
  };

  const showRewardModal = (refreshData: () => void) => {
    const isTest = !!participant.respondent.recruit?.isTest;

    if (study.incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL) {
      setShowConfirmExternalIncentiveModal(true);
    } else {
      modal.confirm({
        title: isTest ? "Send a demo incentive email" : "Incentivize participant",
        icon: null,
        content: (
          <div>
            {isTest ? (
              <>
                <p>
                  Would you like to send a demo incentive email to the test participant{" "}
                  <strong>
                    {participant.respondent.person?.firstName} {participant.respondent.person?.lastName[0]}.
                  </strong>
                  ?
                </p>
                <p>
                  As a test participant, they will receive only a demonstration email. You will not be charged and they
                  will not receive an incentive.
                </p>
              </>
            ) : (
              <>
                {study.incentiveType === PROJECT_INCENTIVE_TYPES.CASH ? (
                  <p>
                    Would you like to issue a gift card in the amount of{" "}
                    <strong>
                      {usdFormatterNeat.format(
                        participant.respondent.recruit?.incentive ?? study.defaultIncentive ?? 0
                      )}
                    </strong>{" "}
                    to{" "}
                    <strong>
                      {participant.respondent.person?.firstName} {participant.respondent.person?.lastName[0]}.
                    </strong>
                    ?
                  </p>
                ) : (
                  <p>
                    Would you like to incentivize{" "}
                    <strong>
                      {participant.respondent.recruit?.incentivePoints ?? study.defaultIncentivePoints ?? 0}
                    </strong>{" "}
                    points to{" "}
                    <strong>
                      {participant.respondent.person?.firstName} {participant.respondent.person?.lastName[0]}.
                    </strong>
                    ?
                  </p>
                )}
                {participant.respondent.recruit?.incentivesClearDate ? (
                  <p>
                    For security reasons, we will send the incentive to the participant on{" "}
                    {shiftDate(moment(participant.respondent.recruit.incentivesClearDate)).format("L")}.{" "}
                    <a
                      href="https://app.intercom.com/a/apps/bdqzfylg/articles/articles/5894232/show"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Why is this happening?
                    </a>
                  </p>
                ) : (
                  <p>It may take a few minutes for incentives to be sent.</p>
                )}
              </>
            )}
          </div>
        ),
        okText: isTest ? "Send demo incentive email" : "Incentivize",
        onOk() {
          return rewardMutation().then(
            res => {
              notification.success({ message: "Participant Incentivized" });
              refreshData();
            },
            err => {
              const errors = err?.errors;
              if (errors.length > 0 && errors[0].message === "INSUFFICIENT_FUNDS" && errors[0].extensions) {
                setRecruitsRequiringFunding(errors[0].extensions);
                setShowParticipantPaymentModal(true);
              } else {
                message.error("Error incentivizing participant");
                console.error(err);
              }
            }
          );
        },
        onCancel() {},
        cancelButtonProps: {
          type: "link",
        },
      });
    }
  };

  const ParticipantMenuOptions = {
    NoShow: "No show",
    GivePoints: "Give bonus incentive",
  };

  function handleMenuClick({ key }: { key: string }) {
    switch (key) {
      case ParticipantMenuOptions.NoShow:
        showNoShowModal({ participantId: participant.id, message, modal, notification, refreshData });
        break;
      case ParticipantMenuOptions.GivePoints:
        setGivePointsToParticipantId?.(participant.id);
        break;
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={ParticipantMenuOptions.NoShow}>Mark as No show</Menu.Item>
      {study.status !== PROJECT_STATUSES.DRAFT && !study.tenant.customportal?.hidePointsAndRedemption && (
        <Menu.Item key={ParticipantMenuOptions.GivePoints}>Give bonus incentive</Menu.Item>
      )}
    </Menu>
  );

  return (
    <CellInner width="100%">
      <Dropdown.Button
        overlay={menu}
        trigger={["click"]}
        size="small"
        style={{ width: "100%" }}
        buttonsRender={buttons => {
          buttons[0] = (
            <Button
              block
              style={{ textAlign: "left" }}
              onClick={e => {
                e.stopPropagation();
                showRewardModal(refreshData);
              }}
            >
              <Icon icon={outlinePayments} style={{ marginRight: 4, verticalAlign: "middle" }} />
              Send Incentive
            </Button>
          );
          return buttons;
        }}
      />
      <ParticipantPaymentModal
        study={study}
        visible={showParticipantPaymentModal}
        setVisible={setShowParticipantPaymentModal}
        recruitsRequiringFunding={recruitsRequiringFunding}
        callback={() => {
          rewardMutation().then(res => {
            notification.success({ message: "Participant Incentivized" });
            refreshData();
          });
        }}
      />
      <ConfirmExternalIncentiveModal
        study={study}
        title="Incentivize participant"
        runMutation={async () => {
          await rewardMutation();
          notification.success({ message: "Participant Incentivized" });
          refreshData();
        }}
        visible={showConfirmExternalIncentiveModal}
        setVisible={setShowConfirmExternalIncentiveModal}
      />
    </CellInner>
  );
};

export const ApproveRejectedCell = ({
  study: _study,
  respondent: _respondent,
  refreshData,
}: {
  study: Cells_ApproveRejectedCell_study$key;
  respondent: Cells_ApproveRejectedCell_respondent$key;
  refreshData: () => void;
}) => {
  const study = useFragment(
    graphql`
      fragment Cells_ApproveRejectedCell_study on StudyNode {
        id
        type
        scheduledBy
        usesApiToMarkComplete
      }
    `,
    _study
  );
  const respondent = useFragment(
    graphql`
      fragment Cells_ApproveRejectedCell_respondent on RespondentNode {
        id
      }
    `,
    _respondent
  );

  const { modal, notification } = App.useApp();

  const showApproveConfirm = () =>
    modal.confirm({
      title: "Approve rejected respondent",
      content: "Do you wish to approve this previously rejected respondent?",
      okText: "Yes",
      onOk: () =>
        mutation({
          variables: {
            input: {
              studyId: study.id,
              respondentId: respondent.id,
            },
          },
          mutation: graphql`
            mutation Cells_ApproveRejectedRespondent_Mutation($input: ApproveRespondentInput!) {
              approveRespondent(input: $input) {
                respondent {
                  id
                  approvalStatus
                  masterStatus
                }
                study {
                  needsReviewCount
                  needsPaymentCount
                  approvedTabCount
                  scheduledCount
                  overquotaCount
                  rejectedCount
                  recruits {
                    edges {
                      node {
                        status
                      }
                    }
                  }
                }
              }
            }
          `,
        }).then(() => {
          notifyApproved(1, study, notification);
          refreshData();
        }),
    });

  return (
    <CellInner width={"100%"}>
      <Button size="small" onClick={showApproveConfirm} type="primary">
        Approve
      </Button>
    </CellInner>
  );
};

export const ReviewCell = ({
  respondentId,
  respondentDId,
  panelistDId,
  study: studyKey,
  refreshData,
  buttonSize = "small",
  setResponseModalOpen,
}: {
  respondentId: string;
  respondentDId: string;
  panelistDId?: string;
  study: Cells_ReviewCell_study$key;
  refreshData: () => void;
  buttonSize?: Parameters<typeof Button>[0]["size"];
  setResponseModalOpen?: (value: boolean) => void;
}) => {
  const { modal, notification } = App.useApp();

  const study = useFragment(
    graphql`
      fragment Cells_ReviewCell_study on StudyNode {
        id
        dId
        name
        status
        type
        tenant {
          dId
          name
          vpmAccountId
        }
      }
    `,
    studyKey
  );

  const showApproveConfirm = () =>
    modal.confirm({
      title: "Approve Respondent",
      content: "Do you wish to approve this respondent?",
      okText: "Yes",
      onOk: () =>
        mutation({
          variables: {
            input: {
              studyId: study.id,
              respondentId: respondentId,
            },
          },
          mutation: graphql`
            mutation Cells_ApproveRespondent_Mutation($input: ApproveRespondentInput!) {
              approveRespondent(input: $input) {
                respondent {
                  id
                  approvalStatus
                  masterStatus
                }
                study {
                  needsReviewCount
                  needsPaymentCount
                  approvedTabCount
                  scheduledCount
                  overquotaCount
                  rejectedCount
                  recruits {
                    edges {
                      node {
                        status
                      }
                    }
                  }
                }
              }
            }
          `,
        }).then(() => {
          notification.success({ message: "Participant approved" });
          refreshData();
          setResponseModalOpen?.(false);
          trackEvent("Respondent Approved", {
            ...getRespondentContext({ dId: respondentDId, panelistDId: panelistDId }),
            ...getStudyContext(study),
            ...getTenantContext(study.tenant),
          });
        }),
    });

  return (
    <CellInner width={"100%"}>
      <Button
        size={buttonSize === "middle" ? "middle" : "small"}
        onClick={() => showDenyConfirm({ studyId: study.id, respondentId, modal, notification, refreshData })}
      >
        Decline
      </Button>
      <Button
        size={buttonSize === "middle" ? "middle" : "small"}
        onClick={showApproveConfirm}
        type="primary"
        style={{ marginLeft: "7px" }}
      >
        Approve
      </Button>
    </CellInner>
  );
};

type StudyType = {
  scheduled: MomentType;
  type: string;
  schedulingType: string;
  scheduledBy: string;
};

type ParticipantNodeType = {
  rating: number;
  noShow: boolean;
  incentivized: boolean;
  respondent: {
    approvalStatus: string | null;
    masterStatus: string | null;
  };
};

const showInvitedCell = (participant: ParticipantNodeType, study: StudyType) =>
  participant.respondent.masterStatus === RESPONDENT_MASTER_STATUSES.APPROVED &&
  study.scheduledBy === SCHEDULED_BY.PARTICIPANT &&
  [PROJECT_TYPES.INTERVIEW, PROJECT_TYPES.FOCUS_GROUP].includes(study.type);

const showScheduleCell = (participant: ParticipantNodeType, study: StudyType) =>
  participant.respondent.masterStatus === RESPONDENT_MASTER_STATUSES.APPROVED &&
  study.schedulingType === SCHEDULING_TYPE.HUBUX &&
  study.scheduledBy === SCHEDULED_BY.YOUR_TEAM &&
  [PROJECT_TYPES.INTERVIEW, PROJECT_TYPES.FOCUS_GROUP].includes(study.type);

const showScheduledSlotCell = (participant: ParticipantNodeType, study: StudyType) =>
  participant.respondent.masterStatus === RESPONDENT_MASTER_STATUSES.SCHEDULED &&
  study.schedulingType !== SCHEDULING_TYPE.CALENDLY;

const showParticipationCell = (participant: ParticipantNodeType, study: StudyType) =>
  [RESPONDENT_MASTER_STATUSES.REWARDED, RESPONDENT_MASTER_STATUSES.RATED].includes(
    participant.respondent.masterStatus as RESPONDENT_MASTER_STATUSES
  );

const showPayCell = (participant: ParticipantNodeType, study: StudyType) => {
  const participantFinishedSurvey =
    study.type === PROJECT_TYPES.SURVEY &&
    [RESPONDENT_MASTER_STATUSES.APPROVED, RESPONDENT_MASTER_STATUSES.COMPLETED_SURVEY].includes(
      participant.respondent.masterStatus as RESPONDENT_MASTER_STATUSES
    );
  const participantInterviewed =
    [PROJECT_TYPES.FOCUS_GROUP, PROJECT_TYPES.INTERVIEW].includes(study.type) &&
    participant.respondent.masterStatus === RESPONDENT_MASTER_STATUSES.INTERVIEWED;
  const calendlyScheduled =
    study.schedulingType === SCHEDULING_TYPE.CALENDLY &&
    participant.respondent.masterStatus === RESPONDENT_MASTER_STATUSES.SCHEDULED;

  return participantFinishedSurvey || participantInterviewed || calendlyScheduled;
};

const showNoShowCell = (participant: ParticipantNodeType, study: StudyType) =>
  participant.respondent.masterStatus === RESPONDENT_MASTER_STATUSES.NO_SHOW;

const showApproveRejectedCell = (participant: ParticipantNodeType, study: StudyType, selectedTab: TabType) =>
  // only display "Approve" button for rejected participants on the rejected tab so it doesn't look like a required
  // action on other tabs
  selectedTab === TabType.REJECTED &&
  [RESPONDENT_MASTER_STATUSES.TERMED, RESPONDENT_MASTER_STATUSES.DECLINED].includes(
    participant.respondent.masterStatus as RESPONDENT_MASTER_STATUSES
  );

const showReviewCell = (participant: ParticipantNodeType, study: StudyType) =>
  [RESPONDENT_MASTER_STATUSES.NEEDS_REVIEW, RESPONDENT_MASTER_STATUSES.OVER_QUOTA].includes(
    participant.respondent.masterStatus as RESPONDENT_MASTER_STATUSES
  );

// Needs Review -> Show Menu to allow Manual scheduling for Interviews only
//
// Scheduled for a slot -> Show time slot
//
// Time slot has passed -> Show rating UI
//
// Rating is entered -> Show pay UI (clicking pay opens up confirmation payment modal)
//
// Paid -> Show nothing
export const NextStepCell = ({
  value,
  study: _study,
  refreshData,
  selectedTab,
  openReviewModal,
  setGivePointsToParticipantId,
}: {
  value: { respondent: Cells_NextStepCell_respondent$key };
  study: Cells_NextStepCell_study$key;
  refreshData: any;
  selectedTab: TabType;
  openReviewModal: (participant: any) => void;
  setGivePointsToParticipantId?: (participantId: string) => void;
}) => {
  const study = useFragment(
    graphql`
      fragment Cells_NextStepCell_study on StudyNode {
        allowAutoApproval
        scheduled
        type
        schedulingType
        scheduledBy
        ...Cells_ApproveRejectedCell_study
        ...Cells_PaymentCell_study
        ...ScheduleCell_study
        ...ScheduledSlotCell_study
      }
    `,
    _study
  );
  const respondent = useFragment(
    graphql`
      fragment Cells_NextStepCell_respondent on RespondentNode {
        id
        participant {
          id
          rating
          noShow
          incentivized
          respondent {
            approvalStatus
            masterStatus
          }
        }
        scheduledSlots {
          id
        }
        ...Cells_ApproveRejectedCell_respondent
        ...ScheduleCell_respondent
        ...ScheduledSlotCell_respondent
      }
    `,
    value.respondent
  );

  if (!respondent?.participant) return null;

  if (
    showInvitedCell(respondent.participant, study) ||
    showScheduleCell(respondent.participant, study) ||
    showScheduledSlotCell(respondent.participant, study)
  )
    return <ScheduleCell study={study} respondent={respondent} refreshData={refreshData} />;

  if (
    (study.type === PROJECT_TYPES.INTERVIEW ||
      (study.type === PROJECT_TYPES.FOCUS_GROUP && !!respondent.scheduledSlots?.length)) &&
    showScheduledSlotCell(respondent.participant, study)
  )
    return study.type === PROJECT_TYPES.FOCUS_GROUP ? (
      <ScheduleCell respondent={respondent} study={study} refreshData={refreshData} />
    ) : (
      <ScheduledSlotCell respondent={respondent} study={study} refreshData={refreshData} />
    );

  if (showParticipationCell(respondent.participant, study)) return ParticipationCell({ value }, refreshData);

  if (showNoShowCell(respondent.participant, study)) return NoShowCell({ value }, study);

  if (showPayCell(respondent.participant, study))
    return (
      <PaymentCell
        participant={value}
        study={study}
        refreshData={refreshData}
        setGivePointsToParticipantId={setGivePointsToParticipantId}
      />
    );

  if (showApproveRejectedCell(respondent.participant, study, selectedTab))
    return <ApproveRejectedCell study={study} respondent={respondent} refreshData={refreshData} />;

  if (showReviewCell(respondent.participant, study)) return ResponsesCell(value, openReviewModal);

  return null;
};

const NoShowCell = ({ value }: any, study: any) => {
  if (study.type === PROJECT_TYPES.INTERVIEW || study.type === PROJECT_TYPES.FOCUS_GROUP) {
    if (!value.respondent.scheduledSlot) return <NoShowText />;
    return <CellInner width="100%">No Show</CellInner>;
  } else {
    return <NoShowText />;
  }
};

const NoShowText = () => <CellInner>No Show</CellInner>;

export const StatusCell = (participant: any, schedulingType: string) =>
  participant.respondent.masterStatus === RESPONDENT_MASTER_STATUSES.SCHEDULED ? (
    <RSVPCell rsvp={participant.rsvp} schedulingType={schedulingType} />
  ) : RESPONDENT_MASTER_STATUSES_MAP[participant.respondent.masterStatus as RESPONDENT_MASTER_STATUSES] ? (
    <RecruitStatusCell status={participant.respondent.masterStatus} participant={participant} />
  ) : (
    <CellInner>-</CellInner>
  );

interface IEllipsisCell {
  value: string;
}
export const EllipsisCell = (props: IEllipsisCell) => (
  <Paragraph
    ellipsis={{
      rows: 2,
      tooltip: true,
    }}
  >
    {props.value}
  </Paragraph>
);

export const AnswerCell = (value: any, elementId: string, rowId?: string) => {
  if (value.respondent.screenerResults === null) {
    return <CellInner>-</CellInner>;
  }
  const responses = JSON.parse(value.respondent.screenerResults);

  if (!responses[elementId]) {
    return <CellInner>-</CellInner>;
  }

  // If the text of the answer is longer than 45 characters, we use an ellipsis element to shorten
  // the text, plus a tooltip
  if ((rowId && responses[elementId][rowId]?.length > 45) || (!rowId && responses[elementId]?.length > 45)) {
    return (
      <CellInner width="200px">
        <EllipsisCell
          value={
            rowId ? (responses[elementId][rowId] as string) : (responses[elementId].replaceAll("|", ",") as string)
          }
        />
      </CellInner>
    );
  } else {
    return (
      <CellInner width="200px">
        {rowId
          ? responses[elementId][rowId]
          : typeof responses[elementId] === "string"
          ? responses[elementId].replaceAll("|", ",")
          : ""}
      </CellInner>
    );
  }
};

export const GridAnswerCell = (
  value: any,
  element: IElement,
  studyId: string,
  openReviewModal: (participant: any, rowIndex?: number) => void
) => {
  if (value.respondent.screenerResults === null) {
    return <CellInner>-</CellInner>;
  }
  const responses = JSON.parse(value.respondent.screenerResults);

  if (!responses[element.node.dbId]) {
    return <CellInner>{`No responses with ${element.node.dbId}`}</CellInner>;
  }

  const displayText = element.node.rows.edges.reduce((prev: string, cur: any) => {
    return `${prev && prev + ", "}${cur?.node?.text}: ${responses[element.node.dbId][cur.node.dbId]}`;
  }, "");

  return (
    <CellInner width="305px">
      <Paragraph
        ellipsis={{
          rows: 2,
          tooltip: "Click to view",
        }}
        onClick={() => {
          openReviewModal(value, element.node.position);
        }}
        style={{
          cursor: "pointer",
        }}
      >
        {displayText}
      </Paragraph>
    </CellInner>
  );
};

export const CharacteristicCell = (value: string | null, node: ReactNode | null = null) => {
  if (value === null || value === undefined) {
    return <CellInner height="60px">-</CellInner>;
  }

  // If the text of the answer is longer than 45 characters, we use an ellipsis element to shorten
  // the text, plus a tooltip
  if (value.length > 45) {
    return (
      <CellInner width="100%" height="60px">
        <EllipsisCell value={value} />
      </CellInner>
    );
  } else {
    return (
      <CellInner width="100%" height="60px">
        {node ? (
          // if a node is given, wrap it in a div to prevent text wrap issues with CellInner's flexbox
          <div>{node}</div>
        ) : (
          value
        )}
      </CellInner>
    );
  }
};

type segmentsType = {
  edges?: {
    node?: {
      segment: {
        text: string;
      };
    };
  }[];
};
export const SegmentCell = (segments: segmentsType) => {
  if (segments === null || segments === undefined || segments?.edges?.length === 0) {
    return null;
  }

  const valueArray = segments.edges?.map((node: any) => node?.node.segment?.text || "Unnamed Segment");
  const value = valueArray && valueArray.length > 0 ? valueArray.join(", ") : "-";

  return (
    <CellInner width="200px">
      <EllipsisCell value={value} />
    </CellInner>
  );
};

export const RecruitStatusCell = ({ status, participant }: { status: string; participant?: any }) => {
  const { shiftDate } = useContext(TimeZoneContext);

  const pointsBonusesSummary = participant?.bonusPoints?.reduce(
    (acc: any, cur: any, idx: number) => (
      <>
        {numberFormatter.format(cur.pointsAmount)} bonus points on{" "}
        {shiftDate(moment(cur.orderDate)).format("MMM D, YYYY, h:mm a")}
        {idx > 0 && <br />}
        {acc}
      </>
    ),
    <></>
  );

  const paidText = useCallback(
    (participant: any) => {
      if (participant) {
        const amountText =
          participant.incentivizedType === PROJECT_INCENTIVE_TYPES.CASH
            ? `${participant.incentivizedAmount}`
            : participant.incentivizedType === PROJECT_INCENTIVE_TYPES.POINTS
            ? `${participant.incentivizedPointAmount} points`
            : participant.incentivizedType === PROJECT_INCENTIVE_TYPES.EXTERNAL
            ? "Incentivized"
            : "";

        return `${amountText} on ${shiftDate(moment(participant.incentivizedOn)).format("MMM D, YYYY, h:mm a")}`;
      }
    },
    [shiftDate]
  );

  const tooltipLookup = {
    [RESPONDENT_MASTER_STATUSES.REWARDED]: paidText(participant),
    [RESPONDENT_MASTER_STATUSES.RATED]: paidText(participant),
  };

  const isTest = participant?.respondent?.recruit?.isTest;

  const incentivizedText = tooltipLookup[status as keyof typeof tooltipLookup] ?? "";

  return (
    <Tooltip
      title={
        // a tooltip is always shown for an empty fragment but not an empty string
        incentivizedText && participant?.bonusPoints?.length ? (
          <>
            {incentivizedText}
            <br />
            {pointsBonusesSummary}
          </>
        ) : incentivizedText ? (
          incentivizedText
        ) : participant?.bonusPoints?.length ? (
          pointsBonusesSummary
        ) : (
          ""
        )
      }
      placement="bottom"
      overlayStyle={{ maxWidth: 350 }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Icon
          width="20"
          color={SECONDARY_TEXT_COLOR}
          icon={RESPONDENT_ICONS_MAP[status as RESPONDENT_MASTER_STATUSES]}
        />
        {isTest &&
        [RESPONDENT_MASTER_STATUSES.REWARDED, RESPONDENT_MASTER_STATUSES.RATED].includes(
          status as RESPONDENT_MASTER_STATUSES
        )
          ? "Test Incentivized"
          : RESPONDENT_MASTER_STATUSES_MAP[status as RESPONDENT_MASTER_STATUSES]}
      </div>
    </Tooltip>
  );
};
