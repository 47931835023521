import { Select } from "antd";
import classNames, { type Argument } from "classnames";
import { useContext, type CSSProperties } from "react";

import { TimeZoneContext } from "../../utils";

export const InputTimeZone = ({ className, style }: { className?: Argument; style?: CSSProperties }) => {
  const {
    getTimeZone,
    setIntervalsDisabled: setTimeZoneContextIntervalsDisabled,
    setTimeZonePreferred,
    timeZonePreferred,
    timeZones,
  } = useContext(TimeZoneContext);

  return (
    <Select
      className={classNames("hub-input-time-zone", className)}
      dropdownMatchSelectWidth={224}
      filterOption={(inputValue, option) =>
        !!option &&
        `${option.label}${option.value}`
          .toLowerCase()
          .replace(" ", "_")
          .includes(inputValue.toLowerCase().replace(" ", "_"))
      }
      onBlur={() => setTimeZoneContextIntervalsDisabled?.(false)}
      onChange={x => setTimeZonePreferred?.(getTimeZone(x))}
      onFocus={() => setTimeZoneContextIntervalsDisabled?.(true)}
      placeholder="Time zone"
      showSearch
      style={style}
      value={timeZonePreferred?.name}
    >
      {timeZones
        .sort((a, b) => a.namePretty.localeCompare(b.namePretty))
        .map(x => (
          <Select.Option key={x.name} label={x.namePretty} value={x.name}>
            {x.namePretty} ({x.offsetPretty})
          </Select.Option>
        ))}
    </Select>
  );
};
